import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './UnifiedMessaging.css';
import pathDataLoader from '../utils/pathDataLoader';

const UnifiedMessaging = () => {
  const { currentUser } = useAuth();
  const [gmMessages, setGmMessages] = useState([]);
  const [divineMessages, setDivineMessages] = useState([]);
  const [ascensionData, setAscensionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeSection, setActiveSection] = useState('welcome');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showBackButton, setShowBackButton] = useState(false);
  const [assignedPathJsx, setAssignedPathJsx] = useState(null);
  const panelRef = useRef(null);
  const toggleRef = useRef(null);
  const containerRef = useRef(null);

  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';

  useEffect(() => {
    fetchGmMessages();
    fetchDivineMessages();
    fetchAscensionData();
    loadAssignedPathJsx();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    // Close menu when clicking outside
    const handleClickOutside = (event) => {
      if (
        menuExpanded &&
        containerRef.current && 
        !panelRef.current.contains(event.target) &&
        !toggleRef.current.contains(event.target)
      ) {
        setMenuExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuExpanded]);

  const loadAssignedPathJsx = async () => {
    // Only set loading if we have a path to load
    const jsxPath = localStorage.getItem(`assignedPathJsx_${currentUser?.id}`);
    if (!jsxPath) {
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Map of path components
      const pathComponents = {
        '/deities/CaelumbrisShadowed.jsx': () => import('../pages/deities/CaelumbrisShadowed'),
        '/deities/FirstbornSoulbound.jsx': () => import('../pages/deities/FirstbornSoulbound'),
        '/deities/LibrisKnowledgeTrue.jsx': () => import('../pages/deities/LibrisKnowledgeTrue'),
        '/deities/QueenMabFrozenVeil.jsx': () => import('../pages/deities/QueenMabFrozenVeil'),
        '/deities/QueenTitaniaWildgrowth.jsx': () => import('../pages/deities/QueenTitaniaWildgrowth'),
        '/deities/SacrathorDominion.jsx': () => import('../pages/deities/SacrathorDominion'),
        '/deities/Unbound.jsx': () => import('../pages/deities/Unbound'),
        '/deities/ValdisJustice.jsx': () => import('../pages/deities/ValdisJustice'),
        '/deities/WildscarEmberFury.jsx': () => import('../pages/deities/WildscarEmberFury')
      };

      // Get the import function for this path
      const importComponent = pathComponents[jsxPath];
      if (!importComponent) {
        console.error('No component found for path:', jsxPath);
        setError('Failed to load assigned path content: Component not found');
        return;
      }

      // Import the component
      const module = await importComponent();
      if (module.default) {
        setAssignedPathJsx(() => module.default);
      } else {
        console.error('No default export found in module:', jsxPath);
        setError('Failed to load assigned path content: Invalid component');
      }
    } catch (err) {
      console.error('Error loading assigned path JSX:', err);
      setError('Failed to load assigned path content. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchGmMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/user/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch GM messages');
      const data = await response.json();
      
      // Sort messages to show pinned messages at the top
      const sortedMessages = [...data].sort((a, b) => {
        // First sort by pinned status (pinned messages first)
        if (a.pinned && !b.pinned) return -1;
        if (!a.pinned && b.pinned) return 1;
        
        // Then sort by date (newest first)
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      
      setGmMessages(sortedMessages);
    } catch (err) {
      setError('Failed to load GM messages');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDivineMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/veiled-ascension/messages`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch divine messages');
      const data = await response.json();
      setDivineMessages(data);
    } catch (err) {
      setError('Failed to load divine messages');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAscensionData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/veiled-ascension/data`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch path data');
      const data = await response.json();
      
      if (data?.paths) {
        const transformedData = data.paths.map(path => ({
          id: path.name.toLowerCase().replace(/[^a-z0-9]/g, '-'),
          title: path.name,
          description: path.description,
          attributes: {
            ...(path.boon && { 
              Boon: path.boon.description || (typeof path.boon === 'object' ? path.boon.description : path.boon),
              BoonEffect: path.boon.effect || ''
            }),
          },
          additionalInfo: path.additional_information,
          levels: path.levels || {}
        }));
        setAscensionData(transformedData);
      }
    } catch (err) {
      setError('Failed to load path data');
      console.error(err);
      
      // Try loading from utility as fallback
      try {
        const storedPathId = localStorage.getItem('veiledAscensionPath') || 
                            localStorage.getItem(`assignedPath_${currentUser?.id}`);
        
        if (storedPathId) {
          const pathData = pathDataLoader.getPathData(storedPathId);
          if (pathData?.paths) {
            setAscensionData(pathData.paths.map(path => {
              // Transform path data to include both description and effect for boon
              const transformedPath = pathDataLoader.transformPathData(path);
              if (path.boon) {
                transformedPath.attributes = {
                  ...transformedPath.attributes,
                  Boon: path.boon.description || (typeof path.boon === 'object' ? path.boon.description : path.boon),
                  BoonEffect: path.boon.effect || ''
                };
              }
              return transformedPath;
            }));
          }
        }
      } catch (fallbackErr) {
        console.error('Fallback also failed:', fallbackErr);
      }
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit' 
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const createIframeSrc = (content) => {
    const htmlContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <style>
          body {
            margin: 0;
            padding: 10px 20px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            line-height: 1.6;
            background: #000000;
            overflow-x: hidden;
            /* Set default text color but allow overrides */
            color: #f1f1f1;
          }
          
          h1, h2, h3, h4, h5, h6 {
            margin-top: 1.5em;
            margin-bottom: 0.5em;
          }
          
          a:hover {
            text-decoration: underline;
          }
          
          p {
            margin: 1em 0;
          }
          
          img {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
          }
        </style>
        <script>
          // Ensure iframe content is properly sized
          document.addEventListener('DOMContentLoaded', function() {
            // Send height to parent
            const height = document.body.scrollHeight;
            window.parent.postMessage({ type: 'resize', height: height }, '*');
          });
        </script>
      </head>
      <body>
        ${content}
      </body>
      </html>
    `;
    
    return `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
  };

  const toggleMenu = () => {
    setMenuExpanded(!menuExpanded);
  };

  const renderWelcomeContent = () => {
    // If we have ascension data, show the path info instead of welcome message
    if (ascensionData && ascensionData.length > 0) {
      return renderPathInfo();
    }

    // Show loading state while fetching path data
    if (loading) {
      return (
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading path data...</p>
        </div>
      );
    }

    // Show error if there is one
    if (error) {
      return <div className="error-state">{error}</div>;
    }

    // Default welcome message if no path is assigned
    return (
      <div className="welcome-content">
        <h1>Welcome to First Veil</h1>
        <p>Select a message from the sidebar to view its contents.</p>
      </div>
    );
  };

  const renderMessageList = (type) => {
    const messages = type === 'gm' ? gmMessages : divineMessages;
    
    if (loading && messages.length === 0) {
      return (
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading messages...</p>
        </div>
      );
    }

    if (error && messages.length === 0) {
      return <div className="error-state">{error}</div>;
    }

    if (messages.length === 0) {
      return <div className="empty-state">No messages available</div>;
    }

    return (
      <div className="message-list">
        {messages.map((message) => (
          <div 
            key={message.id || message._id} 
            className={`message-item ${selectedMessage && (selectedMessage.id === message.id || selectedMessage._id === message._id) ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedMessage({...message, type});
              setActiveSection(type === 'gm' ? 'gm-message' : 'divine-message');
              // Don't close the menu on desktop
              if (isMobile) {
                setMenuExpanded(false);
              }
            }}
          >
            <div className="message-list-header">
              <div className="message-list-subject">{message.subject || 'No Subject'}</div>
              {message.pinned && <span className="pinned-badge">PINNED</span>}
            </div>
            <div className="message-list-from">
              From: {message.sender || (type === 'gm' ? 'Game Master' : 'Deity, Unbound')}
            </div>
            <div className="message-list-date">{formatDate(message.createdAt || message.date)}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderSelectedMessage = () => {
    if (!selectedMessage) return null;

    return (
      <div className="full-page-message">
        <div className="message-header">
          <div className="message-info">
            <div className="message-subject">{selectedMessage.subject || 'No Subject'}</div>
            <div className="message-from">From: {selectedMessage.sender || (selectedMessage.type === 'gm' ? 'Game Master' : 'Deity, Unbound')}</div>
            <div className="message-date">{formatDate(selectedMessage.createdAt || selectedMessage.date)}</div>
          </div>
        </div>

        <div className="message-content">
          <iframe
            src={createIframeSrc(selectedMessage.content)}
            title={selectedMessage.subject || 'Message Content'}
            className="message-frame"
            style={{ width: '100%', border: 'none', height: '800px' }}
            onLoad={(e) => {
              // Add a message listener for the iframe to tell us its height
              window.addEventListener('message', (msg) => {
                if (msg.data && msg.data.type === 'resize') {
                  try {
                    e.target.style.height = `${msg.data.height + 60}px`;
                  } catch (err) {
                    console.error('Error adjusting iframe height from message:', err);
                  }
                }
              });
              
              // Backup method if postMessage doesn't work
              if (e.target.contentWindow) {
                try {
                  const body = e.target.contentWindow.document.body;
                  const height = body.scrollHeight;
                  e.target.style.height = `${height + 60}px`;
                } catch (err) {
                  console.error('Error adjusting iframe height:', err);
                }
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderPathInfo = () => {
    if (loading && ascensionData.length === 0) {
      return (
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading path data...</p>
        </div>
      );
    }

    if (error && ascensionData.length === 0) {
      return <div className="error-state">{error}</div>;
    }

    if (ascensionData.length === 0) {
      return <div className="empty-state">No path data available</div>;
    }

    const path = ascensionData[0];

    return (
      <div className="path-info">
        <h2 className="path-title">{path.title}</h2>
        <p className="path-description">{path.description}</p>

        {path.attributes?.Boon && (
          <div className="boon-section">
            <h3 className="boon-title">TOUCH OF THE DIVINE</h3>
            <div className="boon-description">{path.attributes.Boon}</div>
            {path.attributes.BoonEffect && (
              <div className="boon-effect">{path.attributes.BoonEffect}</div>
            )}
          </div>
        )}

        <h3 className="progression-title">Path Progression</h3>
        {Object.entries(path.levels || {}).map(([levelKey, levelData]) => (
          <div className="level-item" key={levelKey}>
            <div className="level-header">
              <div className="level-name">{levelData.name}</div>
              <div className="level-number">Level {levelKey.replace('level_', '')}</div>
            </div>
            <div className="level-features">
              {Object.entries(levelData.features || {}).map(([featureName, featureData]) => (
                <div className="feature-item" key={featureName}>
                  <h4 className="feature-name">{featureName}</h4>
                  <div className="feature-description">
                    {typeof featureData === 'string' 
                      ? featureData 
                      : featureData.description || JSON.stringify(featureData)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleSectionClick = (section) => {
    if (section === 'gm' || section === 'divine') {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
      setSelectedMessage(null);
    }
    
    setActiveSection(section);
    
    // Only close menu on mobile
    if (isMobile) {
      setMenuExpanded(false);
    }
  };

  const handleBackClick = () => {
    setShowBackButton(false);
    setActiveSection(selectedMessage ? (selectedMessage.type === 'gm' ? 'gm-message' : 'divine-message') : 'welcome');
  };

  // Render hamburger button outside of panel
  const renderHamburgerButton = () => {
    return (
      <button 
        className={`hamburger-button ${menuExpanded ? 'active' : ''}`} 
        onClick={(e) => {
          e.stopPropagation();
          toggleMenu();
        }}
        aria-label="Toggle menu"
        ref={toggleRef}
      >
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
    );
  };

  return (
    <div className="unified-messaging-container" ref={containerRef}>
      {/* Hamburger Menu Button */}
      {renderHamburgerButton()}

      {/* Side Panel */}
      <div ref={panelRef} className={`side-panel ${menuExpanded ? 'expanded' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="menu-items">
          <div 
            className={`menu-link ${activeSection === 'gm' || activeSection === 'gm-message' ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              handleSectionClick('gm');
            }}
          >
            <span className="menu-icon">📨</span>
            GM Messages
          </div>
          
          {(activeSection === 'gm' || activeSection === 'gm-message') && renderMessageList('gm')}
          
          <div 
            className={`menu-link ${activeSection === 'divine' || activeSection === 'divine-message' ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              handleSectionClick('divine');
            }}
          >
            <span className="menu-icon">✨</span>
            Divine Messages
          </div>
          
          {(activeSection === 'divine' || activeSection === 'divine-message') && renderMessageList('divine')}
          
          <div 
            className={`menu-link ${activeSection === 'path' ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              handleSectionClick('path');
            }}
          >
            <span className="menu-icon">🛤️</span>
            Veiled Ascension Path
          </div>

          {/* Add Discord and Foundry Links */}
          <div className="menu-link external-link">
            <a
              href="https://discord.gg/ZNqgahpQGQ"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="menu-icon">💬</span>
              Join Discord
            </a>
          </div>

          <div className="menu-link external-link">
            <a
              href="http://47.7.21.116:30000/join"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="menu-icon">🎲</span>
              Launch Foundry
            </a>
          </div>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="content-area">
        {activeSection === 'welcome' && renderWelcomeContent()}
        {activeSection === 'gm-message' && renderSelectedMessage()}
        {activeSection === 'divine-message' && renderSelectedMessage()}
        {activeSection === 'path' && renderPathInfo()}
      </div>
    </div>
  );
};

export default UnifiedMessaging; 